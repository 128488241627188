import { get, map } from 'lodash'
import React from 'react'
import { Flip } from 'react-reveal'

import {
  Columns,
  Content,
  Container,
  Heading,
  Section,
} from 'react-bulma-components'
import {
  Base,
  BasePreview,
  Image as CommonImage,
} from 'components/common'
import ContactForm from 'components/contact-form'
import Hero from 'components/hero'


export default function ContactPageTemplate(props) {
  const {
    accreditations = [],
    heroHeading,
    heroImage,
    heroSubheading,
    previewMode,
    showContactForm,
  } = props

  const BaseComponent = previewMode ? BasePreview : Base
  const heroBackground = get(heroImage, 'childImageSharp.fluid.src')
    || heroImage.value|| null

  return (
    <BaseComponent>
      <Hero backgroundImage={heroBackground} size={showContactForm ? 'medium' : 'large'}>
        <Container
          className="padding-vertical-32"
          color="primary"
        >
          <Columns>
            <Columns.Column size={6}>
              <Flip bottom>
                <Content size="large">
                  <Heading className="has-text-white" size={1}>
                    {heroHeading}
                  </Heading>
                  <Heading className="has-text-white" size={4}>
                    {heroSubheading}
                  </Heading>
                  {showContactForm ? <ContactForm isTranslucent={true} /> : <div />}
                </Content>
              </Flip>
            </Columns.Column>
          </Columns>
        </Container>
      </Hero>
      <Section>
        <Container className="has-text-centered padding-vertical-32">
          <Columns>
            {map(accreditations, (props, index) => (
              <Columns.Column key={index} mobile={{ size: 12 }} size={4}>
                <div style={{ margin: '0 auto', width: 240 }}>
                <CommonImage imageInfo={{ image: props.image }} />
                </div>
              </Columns.Column>
            ))}
          </Columns>
        </Container>
      </Section>
    </BaseComponent>
  )
}
